<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h4 id="top">Positions Trained by Coaches</h4>
      </div>

      <div class="row card search-card">
        <div class="rainbow-background"></div>
        <p class="instructions">Report of what the coaches coached.</p>
        <div class="col-md-12 row">
        </div>
      </div>

      <br>

      <div style="overflow-x:auto;" class="card">
        <table class="card-table table report-table">
          <thead>
          <tr>
            <th></th>
            <template v-for="(title, index) in data.trainee_roles">
              <th colspan="3" :key="index">{{ title }}</th>
            </template>
          </tr>
          <tr>
            <th>Coach</th>
            <template v-for="role in data.trainee_roles">
              <td :key="`name-${role}`">Name</td>
              <td :key="`day-${role}`">Day(s)</td>
              <td :key="`date-${role}`">Date(s)</td>
            </template>
          </tr>
          </thead>
          <tbody>
          <!-- Coach data area -->
          <template v-for="coach in data.coaches">
            <!-- Trainee data area -->
            <template v-for="(num, count) in coach.max_trainees">
              <tr :id="`${count}-${coach.max_trainees}--${coach.uuid}`" class="trainee-row" :key="`${coach.uuid}-${count}`">

                <th v-if="count === 0" :rowspan="coach.max_trainees">{{ coach.name }}</th>

                <template v-for="(role, index2) in data.trainee_roles">

                  <template v-if="coach.roles[role] && coach.roles[role]['trainees'][count]">
                    <td class="smaller-table-font" :id="`${coach.roles[role]['trainees'][count].uuid}`" :key="`name-${index2}`">{{ coach.roles[role]['trainees'][count].name }}</td>
                    <td class="smaller-table-font" :key="`days-${coach.roles[role]['trainees'][count].uuid}-${index2}`">{{ coach.roles[role]['trainees'][count].days }}</td>
                    <td class="smaller-table-font" :key="`dates-${coach.roles[role]['trainees'][count].uuid}-${index2}`">
                      <template v-for="(date, index4) in coach.roles[role]['trainees'][count].dates">
                        <span :key="`${date}-${index4}`">{{ date }} <br/></span>
                      </template>
                    </td>
                  </template>
                  <template v-else>
                    <td :id="``" :key="`name-${coach.uuid}-${index2}`"></td>
                    <td :key="`days-${coach.uuid}-${index2}`"></td>
                    <td :key="`dates-${coach.uuid}-${index2}`"></td>
                  </template>

                </template>

              </tr>
            </template>

            <!-- Total area -->
            <template v-if="coach.max_trainees === 0">
              <tr :key="coach.uuid">
                <td>{{ coach.name }}</td>
              </tr>
            </template>
            <tr v-else class="total-row" :key="`${coach.uuid}`">
              <td class="smaller-table-font"><b>Grand Total:</b> {{ coach.total }}</td>
              <template v-for="count in data.trainee_roles">
                <td class="smaller-table-font" :key="`sub_total-${count}`" colspan="3"><b>{{ count }} Total:</b> {{ coach.roles[count]['total'] }}</td>
              </template>
            </tr>
          </template>

          </tbody>
        </table>
      </div>

    </div>
    <div v-else>
      <loading-overlay></loading-overlay>
    </div>
    <button v-on:click="scrollToTop" id="topButton" type="button" class="btn btn-success">TOP</button>
  </div>
</template>

<script>
import { TimeCardActivityUserService } from '@/common/services/time_card_activity_user.service';
import { FilterService } from '@/common/services/filter.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'ReportsTrained',
  mixins: [HelperMethods],
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      loaded: false,
      loadingText: false,
      reportLoaded: false,
      data: [],
    };
  },
  created() {
    this.getCoachedReport()
      .then((response) => {
        this.log('created', response.data);
        this.data = response.data;
        this.loaded = true;
      });
  },
  watch: {
  },
  methods: {
    getCoachedReport() {
      this.filters = FilterService.defaultData();
      this.filters.role_eq = 'Trainee';
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return TimeCardActivityUserService.coachedReport(params);
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-select.single.searching:not(.open):not(.loading) input[type=search] {
    opacity: 1;
  }
  .align-left {
    text-align: left;
  }
  .search-card {
    padding-bottom: 30px;

    .instructions {
      padding: 10px;
      border: 1px solid #dee3e7;
      border-radius: 5px;
      margin: 15px auto 15px;
    }
  }

  #topButton {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    font-size: 15px;

    &:hover {
      background-color: #555;
    }
  }

  .smaller-table-font {
    font-size: 0.6em;
  }

  .total-row {
    border: 10px solid black;
  }

  .trainee-row {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black;
  }

  .report-table {
    border: none;
    border-collapse: separate;
  }

  table, th, td {
    border: none !important;
    border-right: 1px solid black !important;
  }
</style>
